import gql from "graphql-tag";

export const GET_PROFILE = {
    query: gql`
        query getProfile {
            getProfile {
                ref
                email
                name
                surname
                auth {
                    google
                    apple
                    password
                }
                birthDate
                country
                language
                gender
                phone
                emailVerified
                termsAccepted
                marketingAccepted
            }
        }
    `,
    defaultVars: {},
};

export const GET_INVOICE = {
    query: gql`
        query getInvoice($hotelRef: ID!, $roomNumber: Int, $password: String) {
            getInvoice(hotelRef: $hotelRef, roomNumber: $roomNumber, password: $password) {
                currency {
                    position
                    symbol
                }
                items {
                    date
                    description
                    price
                    quantity
                }
                paid
                totalAmount
            }
        }
    `,
};

export const GET_PROJECT_LANGUAGES = {
    query: gql`
        query GetProjectLanguages($hotelRef: ID!, $page: Int, $size: Int, $orderBy: OrderBy) {
            getProjectLanguages(hotelRef: $hotelRef, page: $page, size: $size, orderBy: $orderBy) {
                info {
                    count
                    page
                    size
                }
                results {
                    isDefault
                    languageRef
                }
            }
        }
    `,
};
